<template>
  <div class="ma-0 row-bg-color custom-content-height">
    <div class="bg-white rounded-lg h-100 py-8 pl-0 px-md-6">
      <datatable-detail
        class="col-9 col-md-9 col-sm-9"
        :table_properties="table_properties"
      />
      <v-container fluid>
        <v-row class="mt-8">
          <v-col cols="12" md="6">
            <hr v-if="!!skuTasks" class="mt-0 mb-8 d-none d-md-block" />
            <v-row>
              <v-col cols="12" md="6">
                <div class="font-weight-medium w-100 text-capitalize">
                  <v-text-field
                    ref="tote"
                    v-model="tote_id"
                    label="Scan tote"
                    :disabled="!selectedWarehouse"
                    type="text"
                    autofocus
                    outlined
                    clearable
                    class="poppins rounded-lg"
                    append-icon="mdi-barcode-scan"
                    @click:append="enterTote"
                    @keyup.enter="enterTote"
                    @click:clear="clearAllData"
                  />
                </div>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="justify-content-end align-items-start d-flex"
              >
                <v-text-field
                  v-if="pickingType === 1"
                  ref="sku"
                  v-model="sku"
                  label="Scan SKU"
                  type="text"
                  outlined
                  clearable
                  class="poppins rounded-lg"
                  append-icon="mdi-barcode-scan"
                  @click:append="searchSku"
                  @keyup.enter="searchSku"
                />
              </v-col>
            </v-row>

            <OrdersTable v-if="isOrdersLoaded" />
          </v-col>
          <v-col md="6">
            <single-packing
              :sku="sku"
              :tote="tote_id"
              :refresher="clearAllData"
              @on-close-print="onClosePrint"
              v-if="pickingType === 1 && !!skuTasks && boxInfo"
            />
            <batch-packing
              :refresher="clearAllData"
              v-else-if="pickingType === 2 && !!skuTasks"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

import {
  UPDATE_TABLE_STRUCTURE,
  UPDATE_TOTE_DATA,
  UPDATE_SKU_TASKS,
  SET_ORDERS_TABLE_DATA,
  SET_SKU_TASKS,
  SET_PICK_TYPE,
  SET_SELECTED_TOTE,
  SET_BATCH_PACKING_BOX,
  SET_PACKING_RESPONSE,
  CREATE_NEW_BOX,
  SET_ENTERED_TOTE,
  SET_BOX_INFO,
} from "@/core/services/store/packing2.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import DatatableDetail from "@/own/components/datatable/DatatableDetailNew.vue";
import OrdersTable from "@/own/components/warehouseManagement/packing/OrdersTable.vue";
import SinglePacking from "@/own/components/warehouseManagement/packing/SinglePacking.vue";
import BatchPacking from "@/own/components/warehouseManagement/packing/BatchPacking.vue";

export default {
  name: "Packing",
  components: { DatatableDetail, OrdersTable, SinglePacking, BatchPacking },
  data: () => ({
    table_properties: {
      title: "Packing",
      description: "Manage packing",
    },
    tote_id: null,
    sku: null,
  }),
  methods: {
    enterTote() {
      this.$store.commit(SET_BOX_INFO, null);
      if (this.tote_id) {
        this.$store.commit(SET_ENTERED_TOTE, this.tote_id);
        this.sku = null;
        const payload = {
          warehouse_id: this.selectedWarehouse,
          tote_id: this.tote_id,
        };
        this.$store.dispatch(UPDATE_TOTE_DATA, payload).then(() => {
          if (this.pickingType === 1) {
            setTimeout(() => {
              this.$refs.sku.focus();
            }, 300);
          }
        });
      } else {
        Swal.fire({
          title: `Tote required`,
          icon: "warning",
          text: `Please enter a valid tote`,
        });
      }
    },
    searchSku() {
      if (this.sku) {
        const { tote_id, selectedWarehouse, sku } = this;
        const payload = {
          warehouse_id: selectedWarehouse,
          tote_id,
          sku,
        };

        this.$store.dispatch(UPDATE_SKU_TASKS, payload).then(() => {
          this.$store.dispatch(CREATE_NEW_BOX, {
            system_order_number: this.orderDetails.system_order_number,
          });
        });
      }
    },
    clearAllData() {
      this.tote_id = null;
      this.sku = null;

      this.$store.commit(SET_ORDERS_TABLE_DATA, null);
      this.$store.commit(SET_SKU_TASKS, null);
      this.$store.commit(SET_PICK_TYPE, null);
      this.$store.commit(SET_SELECTED_TOTE, null);
      this.$store.commit(SET_BATCH_PACKING_BOX, null);
      this.$store.commit(SET_PACKING_RESPONSE, {
        message: "",
        status: false,
        success: false,
      });
    },
    onClosePrint() {
      this.sku = null;
      const pickType = this.$store.getters.getWMSPACKINGPickType;
      const isFullyPacked = this.$store.getters.getWMSPACKINGIsFullyPacked;

      if (pickType === 1) {
        if (isFullyPacked) {
          this.clearAllData();
        } else {
          setTimeout(() => {
            this.$refs.sku.focus();
          }, 300);
        }
      }
    },
  },
  computed: {
    selectedWarehouse: function () {
      return this.$store.getters.getSelectedWarehouse;
    },
    isOrdersLoaded: function () {
      return !!this.$store.getters.getWMSPACKINGOrdersTableData;
    },
    pickingType: function () {
      return this.$store.getters.getWMSPACKINGPickType;
    },
    skuTasks: function () {
      return this.$store.getters.getWMSPACKINGSKUTasks;
    },
    orderDetails: function () {
      return this.$store.getters.getWMSPACKINGOrderDetails;
    },
    boxInfo: function () {
      return this.$store.getters.getWMSPACKINGBoxInfo;
    },
  },
  beforeMount() {
    this.clearAllData();
  },
  mounted() {
    this.$store.commit(SET_PAGE_LOADING, true);

    this.$store.dispatch(UPDATE_TABLE_STRUCTURE).then(() => {
      this.$store.commit(SET_PAGE_LOADING, false);
    });
  },
};
</script>
